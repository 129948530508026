import _ from 'lodash';
import $ from 'jquery';
import React from 'react';
import ReactDOM from 'react-dom';
import SoqlHelpers from 'common/visualizations/dataProviders/SoqlHelpers';

import AssetTypeIcon from 'common/components/AssetTypeIcon';
import AutoComplete from 'common/components/Autocomplete';
import { BlobDownload, BlobPreview } from 'common/components/BlobbyDatasets';
import Checkbox from 'common/components/Checkbox';
import ColorPicker from 'common/components/ColorPicker';
import DatePicker from 'common/components/DatePicker';
import DateRangePicker from 'common/components/DateRangePicker';
import Dropdown from 'common/components/Dropdown';
import EditBar from 'common/components/EditBar';
import EditableText from 'common/components/EditableText';
import Flannel, { FlannelContent, FlannelHeader } from 'common/components/Flannel';
import HorizontalPicklist from 'common/components/HorizontalPicklist';
import InfoPane from 'common/components/InfoPane';
import LabeledIcon from 'common/components/LabeledIcon';
import LayerToggleSelector from 'common/components/LayerToggleSelector';
import MetadataTable from 'common/components/MetadataTable';
import Modal, { ModalContent, ModalFooter, ModalHeader } from 'common/components/Modal';
import Picklist from 'common/components/Picklist';
import QueryEditor from 'common/components/QueryEditor';
import Radiobutton from 'common/components/Radiobutton';
import SideMenu, { MenuListItem, ExpandableMenuListItem } from 'common/components/SideMenu';
import SimpleMultiSelect from 'common/components/SimpleMultiSelect';
import Slider from 'common/components/Slider';
import SocrataIcon from 'common/components/SocrataIcon';
import ThreeStateCheckbox from 'common/components/ThreeStateCheckbox';
import ToastNotification from 'common/components/ToastNotification';
import ViewCard, { ExternalViewCard } from 'common/components/ViewCard';
import confirmation from 'common/components/ConfirmationDialog';

const components = {
  AssetTypeIcon,
  AutoComplete,
  BlobDownload,
  BlobPreview,
  Checkbox,
  ColorPicker,
  DatePicker,
  DateRangePicker,
  Dropdown,
  EditBar,
  EditableText,
  ExpandableMenuListItem,
  ExternalViewCard,
  Flannel,
  FlannelContent,
  FlannelHeader,
  HorizontalPicklist,
  InfoPane,
  LabeledIcon,
  LayerToggleSelector,
  MenuListItem,
  MetadataTable,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Picklist,
  QueryEditor,
  Radiobutton,
  SideMenu,
  SimpleMultiSelect,
  Slider,
  SocrataIcon,
  ThreeStateCheckbox,
  ToastNotification,
  ViewCard,
  confirmation
};

window._ = _;
window.React = React;
window.ReactDOM = ReactDOM;
window.components = components;
window.SoqlHelpers = SoqlHelpers;

$(() => {
  let index = 1;
  while ($(`#example-${index}`).length > 0) {
    const script = '    ' + $(`#example-${index}`).text().trim();
    $(`#styleguide-code-example-${index}`).html($('<pre>').text(script));
    index++;
  }
});
